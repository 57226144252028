<template>
    <div
        id="carouselExampleControls"
        class="carousel slide"
        data-ride="carousel"
    >
        <div v-if="!hasPhotos" class="carousel-inner" style="max-height: 400px">
            <div class="map-img">
                <img src="../assets/Merealadejaotus1960x600.png" class="d-block w-100" alt="merealadejaotus"/>
            </div>
        </div>
        <div v-else class="carousel-inner" style="max-height: 400px">
            <div :class="'carousel-item'+(idx == 0 ? ' active' : '')" data-interval="10000" v-for="(photoUrl,idx) in photoUrls" :key="idx">
                <img :src="photoUrl" class="d-block w-100" alt="..."/>
            </div>
        </div>
        <a
            v-if="photoUrls.length > 1"
            class="carousel-control-prev"
            :class="{ hideControls: !hasPhotos }"
            href="#carouselExampleControls"
            role="button"
            data-slide="prev"
        >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">{{ $t('previous') }}</span>
        </a>
        <a
            v-if="photoUrls.length > 1"
            class="carousel-control-next"
            :class="{ hideControls: !hasPhotos }"
            href="#carouselExampleControls"
            role="button"
            data-slide="next"
        >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">{{ $t('next') }}</span>
        </a>
    </div>
</template>

<script>
import EventBus from "../eventBus";
import PortsDataService from "@/service/PortsDataService";

export default {
    name: "CarouselSlider",
    data() {
        return {
            photoUrls: [],
        };
    },
    computed: {
        hasPhotos() {
            return this.photoUrls.length;
        }    
    },
    methods: {
        getPhotoUrl(portId, photo) {
            return PortsDataService.queryFileDownloadLink(portId, photo.id).then(response => response.data);                 
        }
    },
    mounted() {
        EventBus.$on("PORTPHOTO", payload => {
            payload.portPhotos.forEach(async photo => this.photoUrls.push(await this.getPhotoUrl(payload.portId, photo)));
        });
    }
};
</script>

<style scoped>
.map-img {
    background: #e0e0e0;
}

.map-img img {
    margin: 0 auto;
    width: 80% !important;
}

.hideControls {
    display: none;
}

</style>
