import Vue from "vue";
import Router from "vue-router";

const portDetails = () => import("../components/PortDetails.vue");
const allPorts = () => import("../components/AllPorts.vue");
const contacts = () => import("../components/Contacts.vue");
const news = () => import("../components/News.vue");
const landingPage = () => import("../components/LandingPage.vue");

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: '/public/',
  routes: [
    {
      path: "/sadam/:id",
      name: "Port Details",
      component: portDetails
    },
    {
      path: "/sadamad",
      name: "All Ports",
      component: allPorts
    },
    {
      path: "/kontaktid",
      name: "Contacts",
      component: contacts
    },
    {
      path: "/teated/:id",
      name: "News",
      component: news
    },
    {
      path: "/",
      name: "Landing Page",
      component: landingPage
    },
    {
      path: "/taraAuth",
      name: "TARA",
      component: landingPage
    },
    {
      path: "/taraBack",
      name: "TARA back",
      component: landingPage
    }
  ]
});

export default router;
