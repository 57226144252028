<template>
    <!-- Sidebar -->
    <div>
        <nav class="d-md-none navbar navbar-expand-md navbar-light bg-white fixed-top shadow">
            <a class="navbar-brand text-primary" :href="showPage('/')">Sadamaregister</a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapse" aria-expanded="false" aria-controls="collapse">
                <span class="navbar-toggler-icon"></span>
            </button>
        </nav>
        <div class="collapse width d-md-block" id="collapse">
            <nav id="sidebar" class="pt-5 pb-3 py-md-0">
                <a class="d-none d-md-block bg-white h1 font-weight-light text-center text-decoration-none py-5" :href="showPage('/')" tabindex="1">
                    Sadamaregister
                </a>
                <div class="d-flex justify-content-between mt-4 mx-3 mb-3 m-md-3">
                    <div>
                        <a class="text-white" data-toggle="modal" data-target="#accessibilityModal" tabindex="2" >
                            {{ $t('sidebar.accessibility') }}
                        </a>
                    </div>
                    <div>
                        <a class="text-white mr-3" tabindex="3" @click="changeLanguage('et')">EST</a>
                        <a class="text-white" tabindex="4" @click="changeLanguage('en')">ENG</a>
                    </div>
                </div>
                <div class="card mx-3 my-1">
                    <div class="card-header">
                        <i class="fa fa-user"></i>
                        {{ $t('sidebar.login') }}
                    </div>
                    <div class="card-body">
                        <button
                            class="btn btn-primary login-btn"
                            @click="stateAuth()"
                            title="Sisene läbi Riigi autentimisteenuse"
                            tabindex="5"
                        >{{ $t('sidebar.authenticationService') }}
                        </button>
                    </div>
                </div>
                <div class="card mx-3 my-1">
                    <div class="card-header">
                        <i class="fa fa-search"></i>
                        {{ $t('sidebar.search') }}
                    </div>
                    <div class="card-body ports-multiselect-body">
                        <form class="ports-multiselect">
                            <multiselect
                                :options="ports"
                                label="name"
                                :placeholder="$t('sidebar.searchParameters')"
                                @select="selectPort"
                                :max-height="350"
                                :internal-search="false"
                                @search-change="search"
                                id="ajax"
                                :searchable="true"
                                select-label=""
                                :tabindex="6"
                                ref="searchPort"
                            >
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc primary">
                                        <div class="option__title" style="font-size: 0.85rem">{{ props.option.name }}</div>
                                        <small class="text-muted">{{ props.option.address }}</small>
                                    </div>
                                </template>
                                <template v-slot:noOptions>{{ $t('noItems') }}</template>
                                <template v-slot:noResult>{{ $t('noResults') }}</template>
                            </multiselect>
                        </form>
                    </div>
                </div>
                <ul class="main-menu mx-3 mb-1">
                <li>
                    <a @click="showPorts(true)" tabindex="7">  <!--see siis peab viima lehele sadamad kus on avatud filter--> 
                        <i class="fa fa-search"></i>
                        {{ $t('sidebar.detailSearch') }}
                    </a>
                </li>
                <li>
                    <a @click="showPorts(false)" tabindex="8">
                        <i class="fa fa-anchor"></i>
                        {{ $t('sidebar.ports') }}
                    </a>
                </li>
                <li>
                    <a :href="showPage('/kontaktid')" tabindex="9">
                        <i class="fa fa-address-card"></i>
                        {{ $t('sidebar.contacts') }}
                    </a>
                </li>
                <li>
                    <a :href="showPage('/teated/latest')" tabindex="10">
                        <i class="fa fa-newspaper"></i>
                        {{ $t('sidebar.news') }}
                    </a>
                </li>
                <li>
                    <a href="https://app.recommy.com/SI/SI2.aspx?td=uZ+7JsagO1M=&tg=LXUOt15SSA0=&TL=5U3QQE93T/0=&si=1&st=1" target="_blank" tabindex="10">
                        <i class="fa fa-commenting"></i>
                        {{ $t('sidebar.feedbackQuestionnaire') }}
                    </a>
                </li>
            </ul>
            <div class="listed-links hide-print m-3">
                <div>{{ $t('sidebar.maritimeAdministrationLinks') }}:</div>
                    <ul>
                        <li><a href="https://www.transpordiamet.ee/" tabindex="11">{{ $t('sidebar.maritimeWebpage') }}</a></li>
                        <li><a href="https://his.vta.ee:8443/HIS/Avalik?REQUEST=Main" tabindex="12">HIS</a></li>
                        <li><a href="https://gis.vta.ee/nutimeri" tabindex="13">Nutimeri</a></li>
                        <li><a href="https://gis.vta.ee/navhoiatused/et.html" tabindex="14">{{ $t('sidebar.navigationalWarnings') }}</a></li>
                        <li><a href="http://nma.vta.ee" tabindex="15">{{ $t('sidebar.NavigationAidsDatabase') }}</a></li>
                    </ul>
                </div>
                <div class="logos mx-3 mb-0 mb-md-3">
                    <a class="logo-phrame" href="https://transpordiamet.ee/">
                        <img src="../assets/Transpordiamet.png">
                        
                    </a>
                    <div class="logo-phrame">
                        <img src="../assets/Regionaal_Fond.png">
                    </div>
                </div>
            </nav>
        </div>
    </div>
    <!-- Sidebar end -->
</template>

<script>
import PortsDataService from "../service/PortsDataService";
import Multiselect from "vue-multiselect";
import EventBus from "../eventBus";
import i18n from "@/i18n";

export default {
    data() {
        return {
            ports: [],
            portsAll: [],
            estLang: '',
            engLang: ''
        };
    },

    components: {Multiselect},
    computed: {
    },
    methods: {
        stateAuth() {
            if (window.appSettings.PasswordAuthEnabled.toLowerCase() === "true"){
                window.location.href = window.appSettings.MainAppUrl;
            }
            else{
                window.location.href = window.appSettings.ApiBaseUrl + "/authentication/challenge?isPersistent=false";
            }
        },
        selectPort(selectedOption) {
            const path = "/sadam/" + selectedOption.publicId;
            window.location.href = path;
        },
        refreshPorts() {
            PortsDataService.queryPorts().then(response => {

                this.portsAll = response.data;

                const collator = new Intl.Collator('et', {
                    numeric: true,
                    sensitivity: 'base'
                });

                this.ports = response.data.sort(function (a, b) {
                    return collator.compare(a.name, b.name)
                });
            });
        },
        async changeLanguage(lang) {
            if ((lang === "et" && this.estLang !== 'active')) {
                EventBus.$emit("LANG", {lang: 'et'});
            }
            if ((lang === "en" && this.engLang !== 'active')) {
                EventBus.$emit("LANG", {lang: 'en'});
            }
        },
        showPage(url) {
            return url;
        },
        showPorts(showFilter) {
            localStorage.removeItem('portTask');
            if (showFilter) {
                localStorage.filterOpened = "opened";
            } else {
                localStorage.filterOpened = "closed";
            }
            document.location.href = "/sadamad";
        },
        search(query) {
            this.ports = this.portsAll.filter(e => e.name.toLowerCase().includes(query.toLowerCase()) || e.address.toLowerCase().includes(query.toLowerCase()));
        },
    },
    created() {
        if (i18n.locale === 'en') {
            this.estLang = '';
            this.engLang = 'active';
        } else {
            this.estLang = 'active';
            this.engLang = '';
        }
        this.refreshPorts();
    },
    mounted() {
        EventBus.$on("LANG", payload => {
            if (payload.lang === 'en') {
                this.estLang = '';
                this.engLang = 'active';
            } else {
                this.estLang = 'active';
                this.engLang = '';
            }
        });
        this.$refs.searchPort.$refs.search.setAttribute("autocomplete", "off")
    }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>
<style lang="scss">
.logos {
    .logo-phrame {
        padding: 1rem;
        height: auto;
        width: 100%;
        background-color: white;
        margin-bottom: .5rem;
        display: flex;
        justify-content: start;
    
        img {
            height: 80px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
