<template>
    <div class="col-xl-8 news">
        <div v-if="newsItem.vaartus && newsItem.vaartus != ''">
            <h3>{{ newsItem.nimi }}</h3>
            <p>{{ $t('newsContent.date') }}: {{ newsItem.alates }}</p>
            <p v-html="renderMarkdown(newsItem.vaartus)">
            </p>
            <div class="socialMedia">
                <div class="fb-like" :data-href="facebookPage()" data-layout="button" data-colorscheme="dark" data-action="like"
                     data-size="large" data-share="true"></div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from "../eventBus";
import {marked} from "marked";
import DOMPurify from "dompurify";

export default {
    name: "NewsContent",
    props: ["newsItem"],
    data() {
        return {
            estLang: true
        };
    },
    methods: {
        facebookPage() {
            return "/teated/" + this.id;
        },
        renderMarkdown(markdown) {
            return DOMPurify.sanitize(marked.parse(markdown));
        },
    },
    
    mounted() {
        EventBus.$on("LANG", payload => {
            const fbScript = document.createElement('script')
            if (payload.lang === 'et') {
                fbScript.setAttribute('src', 'https://connect.facebook.net/et_EE/sdk.js#xfbml=1&version=v6.0')
            } else {
                fbScript.setAttribute('src', 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v6.0')
            }
            document.head.appendChild(fbScript)
        });
    }
};
</script>

<style scoped>
</style>
