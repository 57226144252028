<template>
    <div class="row">
        <news-content ref="newsContent" :news-item="selectedNewsItem"></news-content>
        <div class="col col-xl-4">
            <news-list ref="newsList" :news="news"></news-list>
        </div>
    </div>
</template>

<script>
import EventBus from "../eventBus";
import PortsDataService from "@/service/PortsDataService";

export default {
    name: "News",
    data() {
        return {
            news: [],
            selectedNewsItem: {}
        };
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
    },
    async created() {
        this.news = (await PortsDataService.news()).data;
        this.selectedNewsItem = this.id === 'latest'
            ? this.news[0]
            : this.news.find(n => n.id === this.id) ?? (await PortsDataService.newsById(this.id)).data;
        EventBus.$emit("MAP", {showMap: false});
    },
};
</script>

<style scoped></style>
