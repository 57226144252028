<template>
    <div class="wrapper" id="app">

        <div id="ie-notice" style="display: none;">
            <div v-html="$t('ie.notice')">
            </div>
            <span id="close">x</span>
        </div>


        <sidebar></sidebar>
        <!-- Page Content -->
        <div class="content">
            <port-message ref="portMessage"/>
            <template v-if="showMap">
                <carousel-slider></carousel-slider>
            </template>

            <main role="main" class="body" v-cloak>
                <router-view></router-view>
            </main>
            <footer class="footer" v-if="showFooter">
                <div class="quick-search">
                    <div class="qs-header"><strong>{{ $t('quickFind.title') }}</strong></div>
                    <div><a
                        @click="showPorts(1, 'Sadamateenuseid osutatakse sõltumata veesõiduki suurusest', 'Port services are provided regardless of the size of the craft')">{{
                            $t('quickFind.port')
                        }}</a></div>
                    <div><a
                        @click="showPorts(2, 'Sadamateenuseid osutatakse vaid alla 24-meetrise kogupikkusega veesõidukitele (väikesadam)', 'Port services are provided for ships less than 24 meters in overall length of vessels')">{{
                            $t('quickFind.smallPort')
                        }}</a></div>
                    <div><a
                        @click="showPorts(3, 'Väikesadam, kus ei osutata tasulisi sadamateenuseid', 'A small port, where paid services are not rendered')">{{
                            $t('quickFind.SmallPortWithNoFees')
                        }}</a></div>
                </div>
                
                <div v-html="footer1">

                </div>
                <div>
                    <div v-html="footer2">
                    </div>
                    <a href="https://www.facebook.com/EestiTranspordiamet" style="color: #6C757D"><i class="fab fa-facebook-square"></i></a>
                </div>

            </footer>
        </div>
        <accessibility-modal></accessibility-modal>
    </div>
</template>

<script>
import * as $ from "jquery";
import PortsDataService from "./service/PortsDataService";
import i18n from "./i18n";
import EventBus from "./eventBus";

export default {
    name: "app",
    data() {
        return {
            content: '',
            footer1: '',
            footer2: '',
            showMap: true,
            showFooter: true
        };
    },
    async mounted() {
        this.footer1 = this.$i18n.t('footer.column1').join('');
        this.footer2 = this.$i18n.t('footer.column2').join('');
        EventBus.$on("MAP", payload => {
            this.showMap = payload.showMap;
        });
        EventBus.$on("FOOTER", payload => {
            this.showFooter = payload.showFooter;
        });
        EventBus.$on("LANG", async payload => {
            if (i18n.locale !== payload.lang) {
                switch (payload.lang) {
                    case 'et':
                        await PortsDataService.changeToEstonian()
                        break;
                    case 'en':
                        await PortsDataService.changeToEnglish();
                        break;
                    default:
                        throw new Error("Unknown language: " + payload.lang);
                }
                
                window.location.reload();
            }
        });
        
        // Initialize language in components.
        EventBus.$emit("LANG", {lang: i18n.locale});
    },
    methods: {
        showPorts(type, estName, engName) {
            let task;
            if (i18n.locale === 'et') {
                task = {'description': estName, 'type': type};
            } else {
                task = {'description': engName, 'type': type};
            }
            localStorage.removeItem('portTask');
            localStorage.setItem('portTask', JSON.stringify(task));
            document.location.href = "/sadamad";
        },
    },
};

$(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip({trigger: "click"})
        .on('shown.bs.tooltip', function () {
            setTimeout(function () {
                $('[data-toggle="tooltip"]').tooltip('hide');
            }, 2500);
        });

});

window.addEventListener('DOMContentLoaded', function () {
    if (window.document.documentMode) {
        const ieNotice = document.querySelector('#ie-notice');
        const app = document.querySelector('#app');
        const sidebar = document.querySelector('#sidebar');
        const closeBtn = document.querySelector('#ie-notice #close');


        ieNotice.style.display = 'block';
        app.style.paddingTop = '80px';
        sidebar.style.paddingTop = '80px';

        closeBtn.addEventListener('click', function () {
            ieNotice.style.display = 'none';
            app.style.paddingTop = '';
            sidebar.style.paddingTop = '';
        });
    }
});

</script>

<style scoped>
template {
    width: 62.5rem;
}

nav {
    width: 62.5rem;
}
</style>
