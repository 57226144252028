import Vue from "vue";
import App from "./App.vue";
import "bootstrap";
import "./scss/app.scss";
import router from "./router";

import Multiselect from "vue-multiselect";
import i18n from "./i18n";
import axios from "axios";
import PortsDataService from "./service/PortsDataService";

Vue.component("multiselect", Multiselect);
Vue.component("sidebar", require("./components/Sidebar.vue").default);
Vue.component(
    "accessibility-modal",
    require("./components/AccessibilityModal.vue").default
);
Vue.component(
    "news",
    require("./components/News.vue").default
);
Vue.component(
    "news-list",
    require("./components/NewsList.vue").default
);
Vue.component(
    "news-content",
    require("./components/NewsContent.vue").default
);
Vue.component("port-message", require("./components/PortMessage.vue").default);
Vue.component("carousel-slider", require("./components/CarouselSlider.vue").default);
Vue.component('pulse-loader', require('vue-spinner/src/PulseLoader.vue').default);

Vue.config.productionTip = false;
Vue.config.devtools = true;

const developmentSettings = {
    ApiBaseUrl: "https://localhost:7119",
    GisBaseUrl: "https://xgis.maaamet.ee/xgis2/page/app/sadamakaart",
    MainAppUrl: "http://localhost:5173",
    PasswordAuthEnabled: "true",
    CaptchaSiteKey: "6LcxQ_kkAAAAAKqSORehtjCxJwMIESZ5Am54ftzI" 
};
const getData = async (url: string) => {
    return (await axios.get(url)).data;
}

const getSettings = async () => process.env.NODE_ENV === 'development'
    ? developmentSettings
    : await getData(`/settings`);

getSettings()
    .then(settings => (window as any).appSettings = settings)
    .then(async () => {
        const apiLanguage = ((await PortsDataService.getLanguage()).data as string).split('-')[0];

        // Some other sites have links with language parameter
        const languageParameter = new URLSearchParams(window.location.search).get('language');
        const languageOverride = languageParameter === 'et' || languageParameter === 'en' ? languageParameter : null;

        if (languageOverride && languageOverride !== apiLanguage) {
            switch (languageOverride) {
                case 'et':
                    await PortsDataService.changeToEstonian()
                    break;
                case 'en':
                    await PortsDataService.changeToEnglish();
                    break;
                default:
                    /* eslint-disable-next-line no-console */
                    console.error("Unknown language parameter value: " + languageOverride);
            }
        }

        i18n.locale = languageOverride ?? apiLanguage;
        document.title = i18n.t('landingPage.header') as string;
    })
    .then(() =>
    {
        new Vue({
            components: {App},
            router,
            i18n,
            render: h => h(App)
        }).$mount("#app");
    });


