<template>

    <div class="card hide-print">
        <div class="card-header">
            {{ $t('news') }}
        </div>
        <div class="card-body">
          <span v-for="n in news" :key="n.id">
            <h6 class="no-href-link"><a :href="openNews(n.id)">{{ n.nimi }}</a></h6>
            <p v-html="renderMarkdown(n.vaartusL)"></p>
            <hr/>
          </span>
        </div>
    </div>

</template>

<script>
import {marked} from "marked";
import DOMPurify from "dompurify";

export default {
    name: "NewsList",
    props: ["news"],
    methods: {
        openNews(id) {
            return "/teated/" + id;
        },
        renderMarkdown(markdown) {
            return DOMPurify.sanitize(marked.parse(markdown));
        },
    },

};
</script>

<style scoped></style>
